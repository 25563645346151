<template>
  <div class="feature-box"  :class="active ? 'feature-box-active' : ''" :style="global.state.isMobile ?  { opacity } : {backgroundColor: past ? '#EBEFFF' : 'white'}" >
    <div class="feature-box-header" @click="$emit('card-click')">
      <icon-box class="feature-icon-box" :active="active" :icon="active ? icon + '-dark' : icon" :size="0.9" />
      <span class="feature-box-label">{{ $t(label) }}</span>
    </div>
    <div class="feature-box-content" :class="active ? 'feature-box-content-active' : ''">
        <span class="feature-box-text" v-html="active ? $t(content) : ''" />
    </div>
  </div>
</template>

<script>
import { inject } from "vue";

import IconBox from "@/components/common/IconBox";
export default {
  name: "FeatureCard",
  components: {
    'icon-box': IconBox
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    content: {
      type: String,
      default: ""
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    past: {
      type: Boolean,
      default: false
    },
    opacity: {
      type: Number,
      default: 1
    }
  },
  setup() {
    const global = inject("global")
    return {
       global
    }
  }
}
</script>

<style scoped>
  .feature-box {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    z-index: 150;
    height: 7.1vh;
    width: 18vh;
    border-radius: 10px;
    box-shadow: 0 18.33px 36.65px rgba(0, 0, 0, 0.1);
    transition: all 0.6s, opacity 0ms;
  }

  .feature-box-active {
    background-color: #627DFF!important;
    width: 23.5vh;
    height: 15.2vh;
    align-items: flex-start;
    padding: 1vh;
  }

  .feature-box-header {
    display: flex;
    align-items: center;
    cursor: pointer;
    z-index: 11;
    height: 7.1vh;
    width: 18vh;
  }

  .feature-box-active .feature-box-header {
    height: auto;
    padding: 1vh 0;
    width: 23.5vh;
  }
  .feature-box-label {
    font-weight: 600;
    font-size: 1.7vh;
    line-height: 180%;
    color: #2E293D;
    padding: 0 1vh;
  }
  .feature-box-active .feature-box-label {
    color: white;
  }

  .feature-box-content {
    display: none;
    flex-basis: 0;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    color: white;
    padding:  0 0.5vh 0.5vh;
    box-sizing: border-box;
    transform-origin: 50% 50%;
    z-index: 10;
  }

  .feature-box-content-active {
    display: flex;

  }

  .feature-box-text {
    padding: 0;
    font-weight: 400;
    font-size: 1.6vh;
    line-height: 150%;
    text-align: start;
    letter-spacing: 0.002em;

  }

  @media screen and (max-width: 992px) {
    .feature-box-active {
      width: 100%;
      height: calc(var(--vh) * 17 - 20px);
      padding: calc(var(--oh) * 1.7) 5vw;
    }

    .feature-icon-box {
      margin-left: 0;
    }

    .feature-box-active .feature-box-header {
      height: calc(var(--oh) * 4.5);
      font-size: calc(var(--oh) * 1.7);
      padding: 0;
    }


    .feature-box-active .feature-box-text {
      padding: 0;
      font-size: calc(var(--oh) * 1.6);

    }
  }

  @keyframes conditionalOpen {
    from { transform: translate(-50%,0) scale(0.5,0.5); opacity: 0; }
    to { transform: translate(-50%,0) scale(1,1); opacity: 1 }
  }

  @keyframes conditionalClose {
    from { transform: translate(-50%,0) scale(1,1); opacity: 1; }
    to { transform: translate(-50%,0) scale(0.5,0.5); opacity: 0; }
  }
  @-webkit-keyframes conditionalOpen {
    from { transform: translate(-50%,0) scale(0.5,0.5); opacity: 0; }
    to { transform: translate(-50%,0) scale(1,1); opacity: 1 }
  }

  @-webkit-keyframes conditionalClose {
    from { transform: translate(-50%,0) scale(1,1); opacity: 1; }
    to { transform: translate(-50%,0) scale(0.5,0.5); opacity: 0; }
  }
</style>