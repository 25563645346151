<template>
  <section class="custom-section" :style="{ height: height }">
    <div class="section-slot" :id="compId">
      <slot></slot>
    </div>
  </section>
</template>

<script>
export default {
  name: "GenericSection",
  props: {
    compId: {
      type: String,
      default: ""
    },
    bgColor: {
      type:String,
      default: "white"
    },
    height: {
      type: String,
      default: "100vh"
    }
  }
}
</script>

<style scoped>
  .custom-section {
    width: 100vw;
    position: relative;
  }
  .section-slot {
    box-sizing: border-box;
    height: 100%;
    padding: 0 10%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  @media screen and (max-width: 992px) {
    .custom-section {
      width: 100%;
    }
    .section-slot {
        padding: 0
    }
  }
</style>