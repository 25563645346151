export default {
    passiveIfSupported() {
        try {
            window.addEventListener("test", null,
                Object.defineProperty(
                    {},
                    "passive",
                    {
                        get: function() { return { passive: true }; }
                    }
                )
            );
        } catch {
            return false
        }
    }
}