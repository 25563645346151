<template >
  <div class="feature-pages" id="feature-pages" ref="pagesRef">
    <img v-if="!global.state.isMobile" src="../../assets/featurePages/page-camera.png" class="feature-page" />
    <img src="../../assets/featurePages/page-scan.jpg" class="feature-page" />
    <img src="../../assets/featurePages/page-ocr.jpg" class="feature-page" />
    <img src="../../assets/featurePages/page-signature.jpg" class="feature-page" />
    <img src="../../assets/featurePages/page-edit.jpg" class="feature-page" />
    <img src="../../assets/featurePages/page-share.jpg" class="feature-page" />
    <img src="../../assets/featurePages/page-translate.jpg" class="feature-page" />
    <div class="feature-page" id="testimonial-page">
      <user-review v-bind="activeTestimonial"/>
    </div>

  </div>
</template>

<script>
import { inject } from "vue";

import UserReview from "@/components/common/UserReview";
export default {
  name: "FeaturePages",
  components: {
    "user-review": UserReview
  },
  props: {
    activeTestimonial: {
      type: Object,
      default: () => {}
    }
  },
  setup() {
    const global = inject("global")
    return {
      global
    }
  }
}
</script>

<style scoped>
.feature-pages {
  height: 800%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  will-change: transform;

}
.feature-page {
  width: 100%;
  height: 12.5%;
  display: block;
  margin: 0;
  padding: 0;
}
#testimonial-page {
  background-color: white;
  box-sizing: border-box;
  padding: 20px 25px;
  display: flex;
  align-items: center;
  will-change: contents;
}
@media screen and (max-width: 992px) {
  .feature-pages {
    height: 700%;
  }
  .feature-page {
    height: 14.2857%;
  }
}
</style>