<template>
  <div class="rating">
    <span v-for="index in rating" style="color: #627DFF;" v-bind:key="index">&#9733;</span>
    <span v-for="index in outOf - rating"  style="color: #FECA00;" v-bind:key="index">&#9734;</span>
  </div>
</template>

<script>
export default {
  name: "GenericRating",
  props: {
    rating: {
      type: Number,
      default: 0
    },
    outOf: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style scoped>
  .rating {
    display: inline;
  }
</style>