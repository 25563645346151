<template>
  <div class="user-review" ref="boxRef">
    <div class="user-review-image-container" >
      <svg-icon icon="quote" :size="global.state.isMobile ? 70 : 130" style="position: absolute; top: 2%; left: 10%; z-index: 3;"/>
      <div class="user-review-image" id="selected-testimonial-avatar">
        <img class="user-avatar-image" :src="img" />
      </div>
    </div>
    <div class="user-review-content-container">
      <h3 class="user-review-name">{{heading ? heading : "An amazing app!"}}</h3>
      <span class="user-review-content">{{content}}</span>
      <generic-rating :rating="rating" :out-of="5" />
      <span style="font-weight: 600; margin-top: 10px;">{{name + " - " + title}}</span>
    </div>
  </div>
</template>

<script>
import GenericSvgIcon from "@/components/generic/GenericSvgIcon";
import GenericRating from "@/components/generic/GenericRating";
import { watch, ref, onMounted, inject } from "vue";
import animations from "@/utils/animations";
export default {
  name: "UserReview",
  components: {
    "generic-rating": GenericRating,
    "svg-icon": GenericSvgIcon
  },
  props: {
    img: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    heading: {
      type: String,
      default: "An Amazing App!"
    },
    content: {
      type: String,
      default: ""
    },
    rating: {
      type: Number,
      default: 0
    },
    avatarTop: {
      type: Number,
      default: 0
    },
    avatarLeft: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      default: "white"
    },
  },
  setup(props) {
    const boxRef = ref(null);

    const global = inject("global")

    watch(() => props.name, () => {
      animations.fadeIn(boxRef.value)
    })

    onMounted(() => {
      animations.fadeIn(boxRef.value)
    })

    return {
      boxRef,
      global
    }
  }
}
</script>

<style scoped>
  .user-review {
    position: relative;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    will-change: scroll-position;
  }
  .user-review-image-container {
    width: 100%;
    height: 30%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .user-review-image {
    margin: min(30px, 2vh) 0;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 4px solid white;
    z-index: 4;
    position: relative;
  }
  .user-avatar-overlay {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
  }
  .user-avatar-image {
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }
  .user-review-content-container {
    height: 60%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .user-review-name {
    font-weight: 600;
    font-size: min(24px,2vh);
    margin: 0 0 10px 0;
  }

  .user-review-content {
    font-weight: 500;
    font-size: min(14px, 1.4vh);
    line-height: 155%;
    text-align: center;
    letter-spacing: 1px;
    color: #9FAAD1;
    margin-bottom: 10px;
  }

  @keyframes conditionalOpen {
    from { transform: scale(0.5,0.5); opacity: 0; }
    to { transform: scale(1,1); opacity: 1 }
  }

  @-webkit-keyframes conditionalOpen {
    from { transform: scale(0.5,0.5); opacity: 0; }
    to { transform: scale(1,1); opacity: 1 }
  }

  @media screen and (max-width: 992px) {
    .user-review-content {
      font-size: calc(var(--oh) * 1.6);
      line-height: 120%;
    }
    .user-review-name {
      font-size: calc(var(--oh) * 2);
    }
    #selected-testimonial-avatar {
      width: 10vh;
      height: 10vh;
    }
  }
</style>