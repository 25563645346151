<template>
  <div class="icon-box-container" :style="{ borderRadius: 15 * size + 'px',border: active ? '2px solid #88FFFA' : '', height: 38 * size + 'px', width: 37 * size + 'px', minWidth: 37 * size + 'px', minHeight: 38 * size + 'px' }" >
    <div class="icon-box" :style="{ backgroundColor: active ? 'white' : 'black',  height: 33 * size + 'px', width: 33 * size + 'px', '--icon-color': active ? 'black' : 'white' }">
      <svg-icon :icon="icon" :size="17 * size" />
    </div>
  </div>
</template>

<script>
import GenericSvgIcon from "@/components/generic/GenericSvgIcon";
export default {
  name: "IconBox",
  components: {
    "svg-icon": GenericSvgIcon
  },
  props: {
    icon: {
      type: String
    },
    active: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: 1
    }
  }
}
</script>

<style scoped>
  .icon-box-container {
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }
  .icon-box{
    border-radius: 11.6px;
    display: flex;
    justify-content: center;
    align-items: center;
  }



</style>